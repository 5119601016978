import { IEnvironment, IEnvironmentLabel, IEnvironmentName } from './environment.interface';

export const environment: IEnvironment = {
  production: true,
  environmentName: IEnvironmentName.iq,
  environmentLabel: IEnvironmentLabel.iq,
  apiUrl: 'iq.myresqsoftware.com/',
  microservices_ports: {
    printing: null
  },
  sentry_dsn: 'https://95250475707af9e8e10ea3b2f9ed2672@o4507578158612480.ingest.us.sentry.io/4507582147592192',
  sample_rate: 1,
  traces_sample_rate: 0,
  honeypot: 'https://honeypot.iq.myresqsoftware.com/honeypot',
  release: 'frontend_90564ef708bba7ac6fdd631f31eb990496cba349',
  marketplace_master: false,
  releaseVersion: '24.3.0',
};
