import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IReviewSessionCancelResponse,
  IReviewSessionComment,
  IReviewSessionResponseList,
  ISingleCommentRequest,
  IUpdateCommentRequest,
  IWorkflowReviewLockResponse,
  IDeleteCommentRequest,
  IToggleSolveCommentRequest,
  ICreateReplyRequest,
  IUpdateReplyRequest,
} from '../interfaces/review.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  private sdoxRuntimePath = '/api/runtime-user-workflow';
  private reviewSessionPath = '/api/review-session';

  constructor(private apiService: ApiService) { }

  public getReviewers(workflowId: string, versionId: string, stepId: string): Observable<any> {
    return this.apiService.get(`${this.sdoxRuntimePath}/${workflowId}/version/${versionId}/Completer/${stepId}`);
  }

  public sendForReview(workflowId: string, versionId: string, stepId: string): Observable<any> {
    return this.apiService.get(`${this.sdoxRuntimePath}/${workflowId}/version/${versionId}/Completer/${stepId}`);
  }

  public getReviewSessions(workflowUuid: string, versionUuid: string): Observable<IReviewSessionResponseList> {
    return this.apiService.get(`${this.sdoxRuntimePath}/${workflowUuid}/version/${versionUuid}/review-session`);
  }

  public getReviewSessionComments(versionUuid: string): Observable<IReviewSessionComment[]> {
    return this.apiService.get(`${this.reviewSessionPath}/${versionUuid}/comments`)
      .pipe(
        map(res => res.data),
        map((comments: IReviewSessionComment[]) => _.sortBy(comments, ['order_index'])));
  }

  public postComment(reviewSessionUuid: string, comment: ISingleCommentRequest) {
    return this.apiService.post(`${this.reviewSessionPath}/${reviewSessionUuid}/comment`, comment);
  }

  public editComment(versionUuid: string, commentUuid: string, comment: IUpdateCommentRequest) {
    return this.apiService.patch(`${this.reviewSessionPath}/${versionUuid}/comment/${commentUuid}/`, comment);
  }

  public deleteComment(versionUuid: string, commentUuid: string, request: IDeleteCommentRequest) {
    return this.apiService.post(`${this.reviewSessionPath}/${versionUuid}/comment/${commentUuid}/delete/`, request);
  }

  public solveComment(versionUuid: string, commentUuid: string, request: IToggleSolveCommentRequest) {
    return this.apiService.patch(`${this.reviewSessionPath}/${versionUuid}/comment/${commentUuid}/toggle-solved`, request);
  }

  public postReply(versionUuid: string, reply: ICreateReplyRequest) {
    return this.apiService.post(`${this.reviewSessionPath}/${versionUuid}/reply/`, reply);
  }

  public editReply(versionUuid: string, replyUuid: string, reply: IUpdateReplyRequest) {
    return this.apiService.patch(`${this.reviewSessionPath}/${versionUuid}/reply/${replyUuid}`, reply);
  }

  public deleteReply(versionUuid: string, replyUuid: string) {
    return this.apiService.delete(`${this.reviewSessionPath}/${versionUuid}/reply/${replyUuid}/delete`);
  }

  public finalizeReview(workflowUuid: string, versionUuid: string, reviewSessionUuid: string) {
    return this.apiService.post(`${this.sdoxRuntimePath}/${workflowUuid}/version/${versionUuid}/review-session/${reviewSessionUuid}`, {})
      .pipe(map(res => res.data));
  }

  public cancelReviewSession(workflowUuid: string, versionUuid: string, reviewSessionUuid: string): Observable<IReviewSessionCancelResponse> {
    return this.apiService.post(`${this.sdoxRuntimePath}/${workflowUuid}/version/${versionUuid}/review-session/${reviewSessionUuid}/cancel`, {});
  }

  public lock(versionUuid: string): Observable<IWorkflowReviewLockResponse> {
    return this.apiService.post(`${this.reviewSessionPath}/${versionUuid}/lock`, {});
  }

  public extendReviewSession(versionUuid: string, reviewSessionUuid: string): Observable<{ success: true }> {
    return this.apiService.patch(`${this.reviewSessionPath}/${versionUuid}/extend/${reviewSessionUuid}`, {});
  }

  public unlock(versionUuid: string, reviewSessionUuid: string, useKeepAlive = false): Observable<{ success: boolean }> {
    return useKeepAlive ? this.apiService.deleteWithKeepAlive(`${this.reviewSessionPath}/${versionUuid}/unlock/${reviewSessionUuid}`)
      : this.apiService.delete(`${this.reviewSessionPath}/${versionUuid}/unlock/${reviewSessionUuid}`);
  }

  public scrollToComment(versionUuid: string, commentUuid: string): Observable<{ success: true }> {
    return this.apiService.post(`${this.reviewSessionPath}/${versionUuid}/scroll-from/${commentUuid}`, {});
  }
}
