import { ReportTitle, ReportTitleAdmin, ReportsQualityDashboardCardCategories, ReportsValidationProjectDashboardCardCategories, ReportsTitleURLKey, ReportsTitleURLKeyAdmin, ReportsProjectManagementDashboardCardCategories, ReportsProjectComparisonDashboardCardCategories } from "@core/interfaces";

export const DEBOUNCE_TIME = 500;
export const DEBOUNCE_TIME_REVIEWS = 1000;
export const EXTEND_REVIEW_SESSION_THROTTLE_TIME = 10 * 60 * 1000; // 10 minutes
export const PRODUCTION_ONLY_WARNING = 'Only accessible from the Production environment';
export const COGNITO_NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
export const COGNITO_LOCAL_STORAGE_KEY_PREFIX = 'CognitoIdentityServiceProvider';
export const AUTOLOGOUT_SESSION_ID = 'AutologoutSessionId';
export const IMAGE_FILES_SUPPORTED_FORMATS = [
  // .gif
  'image/gif',
  // .jpg
  'image/jpeg',
  'image/pjpeg',
  'image/jpg',
  // .png
  'image/png',
  // .bmp
  'image/bmp',
  'image/x-ms-bmp',
  // .webp
  'image/webp',
];
export const RESET_PASSWORD_ERROR = 'Invalid verification code provided, please try again.';
export const EXPIRED_CODE_EXCEPTION = 'ExpiredCodeException';
export const LIMIT_EXCEEDED_ERROR = 'Attempt limit exceeded, please try after some time.';
export const COGNITO_VERIFY_OTP = 'SETTING_MFA_OTP';

export const MODAL_HEADER_HEIGHT = '60px';
export const MODAL_FOOTER_HEIGHT = '60px';
export const MODAL_CONTENT_Y_PADDING = '48px';
export const MODAL_FULL_HEIGHT_VIEWPORT_Y_PADDING = '64px';

const COMPANY_ADMIN_PENDING_USERS_FILTERS_LOCAL_STORAGE_KEY = 'CompanyAdminPendingSsoUsersFilters';
const COMPANY_ADMIN_USERS_FILTERS_LOCAL_STORAGE_KEY = 'CompanyAdminUsersFilters';

const ADMIN_AUDIT_TRAIL_FULL_STORAGE_KEY = 'AdminAuditTrailFullFilters';
const ADMIN_AUDIT_TRAIL_ADMIN_STORAGE_KEY = 'AdminAuditTrailAdminFilters';
const ADMIN_AUDIT_TRAIL_COMPANY_DETAILS_STORAGE_KEY = 'AdminAuditTrailCompanyDetailsFilters';
const ADMIN_AUDIT_TRAIL_COMPANY_USERS_STORAGE_KEY = 'AdminAuditTrailCompanyUsersFilters';
const ADMIN_AUDIT_TRAIL_COMPANY_SETTINGS_STORAGE_KEY = 'AdminAuditTrailCompanySettingsFilters';
const ADMIN_AUDIT_TRAIL_POD_MANAGEMENT_STORAGE_KEY = 'AdminAuditTrailPodManagementFilters';
const ADMIN_AUDIT_TRAIL_MARKETPLACE_STORAGE_KEY = 'AdminAuditTrailMarketplaceFilters';
const ADMIN_AUDIT_TRAIL_GLOBAL_SYSTEMS_STORAGE_KEY = 'AdminAuditTrailGlobalSystemsFilters';
const ADMIN_AUDIT_TRAIL_CMS_DASHBOARD_STORAGE_KEY = 'AdminAuditTrailCMSDashboardFilters';

const AUDIT_TRAIL_FULL_LOCAL_STORAGE_KEY = 'AuditTrailFullFilters';
const AUDIT_TRAIL_DOCUMENTS_LOCAL_STORAGE_KEY = 'AuditTrailDocumentsFilters';
const AUDIT_TRAIL_TEMPLATES_LOCAL_STORAGE_KEY = 'AuditTrailTemplatesFilters';
const AUDIT_TRAIL_WORKFLOWS_LOCAL_STORAGE_KEY = 'AuditTrailWorkflowsFilters';
const AUDIT_TRAIL_COMPANY_USERS_LOCAL_STORAGE_KEY = 'AuditTrailCompanyUsersFilters';
const AUDIT_TRAIL_PERMISSIONS_LOCAL_STORAGE_KEY = 'AuditTrailPermissionsFilters';
const AUDIT_TRAIL_COMPANY_SETTINGS_LOCAL_STORAGE_KEY = 'AuditTrailCompanySettingsFilters';
const AUDIT_TRAIL_LOGIN_LOCAL_STORAGE_KEY = 'AuditTrailLoginFilters';
const AUDIT_TRAIL_USER_GROUPS_LOCAL_STORAGE_KEY = 'AuditTrailUserGroupsFilters';
const AUDIT_TRAIL_FILTER_TAGS_LOCAL_STORAGE_KEY = 'AuditTrailFilterTagsFilters';
const AUDIT_TRAIL_MODAL_LOCAL_STORAGE_KEY = 'AuditTrailModal>Filters';
const AUDIT_TRAIL_IA_LOCAL_STORAGE_KEY = 'AuditTrailImpactAssessmentsFilters';
const AUDIT_TRAIL_COMPANY_DETAILS_LOCAL_STORAGE_KEY = 'AuditTrailCompanyDetailsFilters';
const AUDIT_TRAIL_COMPANY_CONTACTS_LOCAL_STORAGE_KEY = 'AuditTrailCompanyContactsFilters';
const AUDIT_TRAIL_DEPARTMENTS_SITES_LOCAL_STORAGE_KEY = 'AuditTrailDepartmentsSitesFilters';
const AUDIT_TRAIL_PARENT_CHILD_LOCAL_STORAGE_KEY = 'AuditTrailParentChildFilters';
const AUDIT_TRAIL_COMPANY_SYSTEMS_LOCAL_STORAGE_KEY = 'AuditTrailCompanySystemsFilters';
const AUDIT_TRAIL_LICENSES_LOCAL_STORAGE_KEY = 'AuditTrailLicensesFilters';
const AUDIT_TRAIL_PREFIXES_LOCAL_STORAGE_KEY = 'AuditTrailPrefixesFilters';
const AUDIT_TRAIL_SIGNATURE_SETTINGS_LOCAL_STORAGE_KEY = 'AuditTrailSignatureSettingsFilters';
const AUDIT_TRAIL_REPORTS_EXPORTS_LOCAL_STORAGE_KEY = 'AuditTrailReportsExportsFilters';

const BULK_ASSIGN_LOCAL_STORAGE_KEY = 'BulkAssignFilters';
const BULK_SHARE_TEMPLATES_LOCAL_STORAGE_KEY = 'BulkShareTemplatesFilters';
const BULK_TRANSFER_TEMPLATES_LOCAL_STORAGE_KEY = 'BulkTransferTemplatesFilters';
const BULK_SHARE_WORKSPACE_LOCAL_STORAGE_KEY = 'BulkShareWorkspaceFilters';
const BULK_TRANSFER_WORKSPACE_LOCAL_STORAGE_KEY = 'BulkTransferWorkspaceFilters';

const ALL_PACKAGES_PUBLIC_LOCAL_STORAGE_KEY = 'AllPackagesFiltersPublic';
const ALL_PACKAGES_PRIVATE_LOCAL_STORAGE_KEY = 'AllPackagesFiltersPrivate';

const MY_PACKAGES_UPLOADS_LOCAL_STORAGE_KEY = 'MyPackagesFiltersUploads';
const MY_PACKAGES_DOWNLOADS_LOCAL_STORAGE_KEY = 'MyPackagesFiltersDownloads';

const PENDING_APPROVAL_REQUESTS_LOCAL_STORAGE_KEY = 'PendingApprovalRequestsFilters';
const HISTORY_APPROVAL_REQUESTS_LOCAL_STORAGE_KEY = 'HistoryApprovalRequestsFilters';

const ANALYTICS_COMMON_FILTERS_STORAGE_KEY = 'AnalyticsCommonFilters';
const WORKFLOW_VERSIONS_LOCAL_STORAGE_KEY = 'WorkflowVersionsReport';
const WORKFLOW_TIME_LOCAL_STORAGE_KEY = 'TimeAnalyticsReport';

const RUNTIME = 'runtime';
const TEMPLATE = 'template';
const TEMPLATE_CREATE_WORKFLOW_MODAL = 'template_create_workflow_modal';

const IMPORT_WIZARD_PACKAGE_SELECT_LOCAL_STORAGE_KEY = 'ImportWizardPackageSelectFilters';

const ADMIN_REPORTS_LICENSES_LOCAL_STORAGE_KEY = 'AdminReportsLicensesFilters';
const ADMIN_REPORTS_USAGES_LOCAL_STORAGE_KEY = 'AdminReportsUsagesFilters';
const ADMIN_REPORTS_USERS_LOCAL_STORAGE_KEY = 'AdminReportsUsersFilters';

const REPORTS_QUALITY_DASHBOARD_LOCAL_STORAGE_KEY = 'ReportsQualityDashboardFilters';
const REPORTS_VALIDATION_PROJECT_DASHBOARD_LOCAL_STORAGE_KEY = 'ReportsValidationProjectDashboardFilters';
const REPORTS_PROJECT_MANAGEMENT_DASHBOARD_LOCAL_STORAGE_KEY = 'ReportsProjectManagementDashboardFilters';
const REPORTS_PROJECT_COMPARISON_DASHBOARD_LOCAL_STORAGE_KEY = 'ReportsProjectComparisonDashboardFilters';
const REPORTS_DASHBOARDS_CALENDAR_PERIOD_LOCAL_STORAGE_KEY = 'ReportsDashboardsCalendarPeriodFilters';
const REPORTS_SELECTED_DASHBOARD_LOCAL_STORAGE_KEY = 'ReportsSelectedDashboardFilters';

const REPORTS_SYSTEMS_LOCAL_STORAGE_KEY = 'ReportsSystemsFilters';
const REPORTS_WORKFLOWS_LOCAL_STORAGE_KEY = 'ReportsWorkflowsFilters';
const REPORTS_WORKFLOW_DELIVERABLES_LOCAL_STORAGE_KEY = 'ReportsWorkflowDeliverablesFilters';
const REPORTS_TEST_SCRIPT_EXECUTION_LOCAL_STORAGE_KEY = 'ReportsTestScriptExecutionFilters';
const REPORTS_TASKS_LOCAL_STORAGE_KEY = 'ReportsTasksFilters';
const REPORTS_PROJECT_MANAGEMENT_LOCAL_STORAGE_KEY = 'ReportsProjectManagementFilters';
const REPORTS_REVIEWS_LOCAL_STORAGE_KEY = 'ReportsReviewsFilters';
const REPORTS_PERIODIC_REVIEWS_LOCAL_STORAGE_KEY = 'ReportsPeriodicReviewsFilters';
const REPORTS_DEVIATIONS_LOCAL_STORAGE_KEY = 'ReportsDeviationsFilters'
const REPORTS_OPEN_KPI_ANALYTICS_PRE_FILTERS_LOCAL_STORAGE_KEY = 'ReportsOpenKpiAnalyticsPreFilters'
const REPORTS_SEE_ALL_NAVIGATION = 'ReportsSeeAllNavigation'
const REPORTS_KPI_SEE_ALL_NAVIGATION = 'ReportsKPISeeAllNavigation'

const WORKFLOW_LISTING_MODAL_SUBMIT_DEVIATION_LOCAL_STORAGE_KEY = 'WorkflowListingModalSubmitDeviationFilters';
const WORKFLOW_LISTING_MODAL_SUMMARY_DELIVERABLE_CELL_LOCAL_STORAGE_KEY = 'WorkflowListingModalSummaryDeliverableCellFilters';
const WORKFLOW_LISTING_MODAL_SUMMARY_ENVIRONMENT_LOCAL_STORAGE_KEY = 'WorkflowListingModalSummaryEnvironmentFilters';
const WORKFLOW_LISTING_MODAL_IMPLEMENTATION_ENVIRONMENT_RUNTIME_DELIVERABLE_LOCAL_STORAGE_KEY = 'WorkflowListingModalImplementationEnvironmentRuntimeDeliverableFilters';
const WORKFLOW_LISTING_MODAL_IMPLEMENTATION_ENVIRONMENT_RUNTIME_EXECUTED_DELIVERABLE_LOCAL_STORAGE_KEY = 'WorkflowListingModalImplementationEnvironmentRuntimeExecutedDeliverableFilters';

const MY_TASKS_LOCAL_STORAGE_KEY = 'MyTasksFilters';
const MY_ASSIGNMENTS_LOCAL_STORAGE_KEY = 'MyAssignmentsFilters';

export const FILTERS_LOCAL_STORAGE_KEY = {
  COMPANY_ADMIN_PENDING_SSO_USERS_FILTERS: `company_admin_users_filters[${COMPANY_ADMIN_PENDING_USERS_FILTERS_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_USERS_FILTERS: `company_admin_users_filters[${COMPANY_ADMIN_USERS_FILTERS_LOCAL_STORAGE_KEY}]`,

  MARKETPLACE_ALL_PACKAGES_PUBLIC: `marketplace_filters_runtime[${ALL_PACKAGES_PUBLIC_LOCAL_STORAGE_KEY}]`,
  MARKETPLACE_ALL_PACKAGES_PRIVATE: `marketplace_filters_runtime[${ALL_PACKAGES_PRIVATE_LOCAL_STORAGE_KEY}]`,
  MARKETPLACE_MY_PACKAGES_UPLOADS: `marketplace_filters_runtime[${MY_PACKAGES_UPLOADS_LOCAL_STORAGE_KEY}]`,
  MARKETPLACE_MY_PACKAGES_DOWNLOADS: `marketplace_filters_runtime[${MY_PACKAGES_DOWNLOADS_LOCAL_STORAGE_KEY}]`,
  MARKETPLACE_PENDING_APPROVAL_REQUESTS: `marketplace_filters_runtime[${PENDING_APPROVAL_REQUESTS_LOCAL_STORAGE_KEY}]`,
  MARKETPLACE_HISTORY_APPROVAL_REQUESTS: `marketplace_filters_runtime[${HISTORY_APPROVAL_REQUESTS_LOCAL_STORAGE_KEY}]`,

  REPORTS_ANALYTICS_COMMON: `reports_filters_runtime[${ANALYTICS_COMMON_FILTERS_STORAGE_KEY}]`,
  REPORTS_WORKFLOW_TIME: `reports_filters_runtime[${WORKFLOW_TIME_LOCAL_STORAGE_KEY}]`,
  REPORTS_WORKFLOW_VERSIONS: `reports_filters_runtime[${WORKFLOW_VERSIONS_LOCAL_STORAGE_KEY}]`,

  RUNTIME: `filters_${RUNTIME}`,
  TEMPLATE: `filters_${TEMPLATE}`,
  TEMPLATE_CREATE_WORKFLOW_MODAL: `filters_${TEMPLATE_CREATE_WORKFLOW_MODAL}`,

  ADMIN_AUDIT_TRAIL_FULL: `audit_trail_filters[${ADMIN_AUDIT_TRAIL_FULL_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_ADMIN: `audit_trail_filters[${ADMIN_AUDIT_TRAIL_ADMIN_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_COMPANY_DETAILS: `audit_trail_filters[${ADMIN_AUDIT_TRAIL_COMPANY_DETAILS_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_COMPANY_USERS: `audit_trail_filters[${ADMIN_AUDIT_TRAIL_COMPANY_USERS_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_COMPANY_SETTINGS: `audit_trail_filters[${ADMIN_AUDIT_TRAIL_COMPANY_SETTINGS_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_POD_MANAGEMENT: `audit_trail_filters[${ADMIN_AUDIT_TRAIL_POD_MANAGEMENT_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_MARKETPLACE: `audit_trail_filters[${ADMIN_AUDIT_TRAIL_MARKETPLACE_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_GLOBAL_SYSTEMS: `audit_trail_filters[${ADMIN_AUDIT_TRAIL_GLOBAL_SYSTEMS_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_CMS_DASHBOARD: `audit_trail_filters[${ADMIN_AUDIT_TRAIL_CMS_DASHBOARD_STORAGE_KEY}]`,

  AUDIT_TRAIL_FULL: `audit_trail_filters[${AUDIT_TRAIL_FULL_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_DOCUMENTS: `audit_trail_filters[${AUDIT_TRAIL_DOCUMENTS_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_TEMPLATES: `audit_trail_filters[${AUDIT_TRAIL_TEMPLATES_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_WORKFLOWS: `audit_trail_filters[${AUDIT_TRAIL_WORKFLOWS_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_COMPANY_USERS: `audit_trail_filters[${AUDIT_TRAIL_COMPANY_USERS_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_PERMISSIONS: `audit_trail_filters[${AUDIT_TRAIL_PERMISSIONS_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_COMPANY_SETTINGS: `audit_trail_filters[${AUDIT_TRAIL_COMPANY_SETTINGS_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_LOGIN: `audit_trail_filters[${AUDIT_TRAIL_LOGIN_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_USER_GROUPS: `audit_trail_filters[${AUDIT_TRAIL_USER_GROUPS_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_FILTER_TAGS: `audit_trail_filters[${AUDIT_TRAIL_FILTER_TAGS_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_MODAL: `audit_trail_filters[${AUDIT_TRAIL_MODAL_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_IA: `audit_trail_filters[${AUDIT_TRAIL_IA_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_COMPANY_DETAILS: `audit_trail_filters[${AUDIT_TRAIL_COMPANY_DETAILS_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_COMPANY_CONTACTS: `audit_trail_filters[${AUDIT_TRAIL_COMPANY_CONTACTS_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_DEPARTMENTS_SITES: `audit_trail_filters[${AUDIT_TRAIL_DEPARTMENTS_SITES_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_PARENT_CHILD: `audit_trail_filters[${AUDIT_TRAIL_PARENT_CHILD_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_COMPANY_SYSTEMS: `audit_trail_filters[${AUDIT_TRAIL_COMPANY_SYSTEMS_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_LICENSES: `audit_trail_filters[${AUDIT_TRAIL_LICENSES_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_PREFIXES: `audit_trail_filters[${AUDIT_TRAIL_PREFIXES_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_SIGNATURE_SETTINGS: `audit_trail_filters[${AUDIT_TRAIL_SIGNATURE_SETTINGS_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_REPORTS_EXPORTS: `audit_trail_filters[${AUDIT_TRAIL_REPORTS_EXPORTS_LOCAL_STORAGE_KEY}]`,
  BULK_ASSIGN: `bulk_assign_filters[${BULK_ASSIGN_LOCAL_STORAGE_KEY}]`,
  BULK_SHARE_TEMPLATES: `bulk_share_filters[${BULK_SHARE_TEMPLATES_LOCAL_STORAGE_KEY}]`,
  BULK_TRANSFER_TEMPLATES: `bulk_transfer_filters[${BULK_TRANSFER_TEMPLATES_LOCAL_STORAGE_KEY}]`,
  BULK_SHARE_WORKSPACE: `bulk_share_filters[${BULK_SHARE_WORKSPACE_LOCAL_STORAGE_KEY}]`,
  BULK_TRANSFER_WORKSPACE: `bulk_transfer_filters[${BULK_TRANSFER_WORKSPACE_LOCAL_STORAGE_KEY}]`,
  IMPORT_WIZARD_PACKAGE_SELECT: `import_wizard_filters[${IMPORT_WIZARD_PACKAGE_SELECT_LOCAL_STORAGE_KEY}]`,

  ADMIN_REPORTS_LICENSES: `reports_filters[${ADMIN_REPORTS_LICENSES_LOCAL_STORAGE_KEY}]`,
  ADMIN_REPORTS_USAGES: `reports_filters[${ADMIN_REPORTS_USAGES_LOCAL_STORAGE_KEY}]`,
  ADMIN_REPORTS_USERS: `reports_filters[${ADMIN_REPORTS_USERS_LOCAL_STORAGE_KEY}]`,

  REPORTS_QUALITY_DASHBOARD: `reports_filters[${REPORTS_QUALITY_DASHBOARD_LOCAL_STORAGE_KEY}]`,
  REPORTS_VALIDATION_PROJECT_DASHBOARD: `reports_filters[${REPORTS_VALIDATION_PROJECT_DASHBOARD_LOCAL_STORAGE_KEY}]`,
  REPORTS_PROJECT_MANAGEMENT_DASHBOARD: `reports_filters[${REPORTS_PROJECT_MANAGEMENT_DASHBOARD_LOCAL_STORAGE_KEY}]`,
  REPORTS_PROJECT_COMPARISON_DASHBOARD: `reports_filters[${REPORTS_PROJECT_COMPARISON_DASHBOARD_LOCAL_STORAGE_KEY}]`,
  REPORTS_DASHBOARDS_CALENDAR_PERIOD: `reports_filters[${REPORTS_DASHBOARDS_CALENDAR_PERIOD_LOCAL_STORAGE_KEY}]`,
  REPORTS_SELECTED_DASHBOARD: `reports_filters[${REPORTS_SELECTED_DASHBOARD_LOCAL_STORAGE_KEY}]`,

  REPORTS_SYSTEMS: `reports_filters[${REPORTS_SYSTEMS_LOCAL_STORAGE_KEY}]`,
  REPORTS_WORKFLOWS: `reports_filters[${REPORTS_WORKFLOWS_LOCAL_STORAGE_KEY}]`,
  REPORTS_WORKFLOW_DELIVERABLES: `reports_filters[${REPORTS_WORKFLOW_DELIVERABLES_LOCAL_STORAGE_KEY}]`,
  REPORTS_TEST_SCRIPT_EXECUTION: `reports_filters[${REPORTS_TEST_SCRIPT_EXECUTION_LOCAL_STORAGE_KEY}]`,
  REPORTS_DEVIATIONS: `reports_filters[${REPORTS_DEVIATIONS_LOCAL_STORAGE_KEY}]`,
  REPORTS_TASKS: `reports_filters[${REPORTS_TASKS_LOCAL_STORAGE_KEY}]`,
  REPORTS_PROJECT_MANAGEMENT: `reports_filters[${REPORTS_PROJECT_MANAGEMENT_LOCAL_STORAGE_KEY}]`,
  REPORTS_REVIEWS: `reports_filters[${REPORTS_REVIEWS_LOCAL_STORAGE_KEY}]`,
  REPORTS_PERIODIC_REVIEWS: `reports_filters[${REPORTS_PERIODIC_REVIEWS_LOCAL_STORAGE_KEY}]`,
  REPORTS_OPEN_KPI_ANALYTICS_PRE_FILTERS: `reports_filters[${REPORTS_OPEN_KPI_ANALYTICS_PRE_FILTERS_LOCAL_STORAGE_KEY}]`,
  REPORTS_SEE_ALL_NAVIGATION: `reports_filters[${REPORTS_SEE_ALL_NAVIGATION}]`,
  REPORTS_KPI_SEE_ALL_NAVIGATION: `reports_filters[${REPORTS_KPI_SEE_ALL_NAVIGATION}]`,

  WORKFLOW_LISTING_MODAL_SUBMIT_DEVIATION: `workflow_listing_filters[${WORKFLOW_LISTING_MODAL_SUBMIT_DEVIATION_LOCAL_STORAGE_KEY}]`,
  WORKFLOW_LISTING_MODAL_SUMMARY_DELIVERABLE_CELL: `workflow_listing_filters[${WORKFLOW_LISTING_MODAL_SUMMARY_DELIVERABLE_CELL_LOCAL_STORAGE_KEY}]`,
  WORKFLOW_LISTING_MODAL_SUMMARY_ENVIRONMENT: `workflow_listing_filters[${WORKFLOW_LISTING_MODAL_SUMMARY_ENVIRONMENT_LOCAL_STORAGE_KEY}]`,
  WORKFLOW_LISTING_MODAL_IMPLEMENTATION_ENVIRONMENT_RUNTIME_DELIVERABLE: `workflow_listing_filters[${WORKFLOW_LISTING_MODAL_IMPLEMENTATION_ENVIRONMENT_RUNTIME_DELIVERABLE_LOCAL_STORAGE_KEY}]`,
  WORKFLOW_LISTING_MODAL_IMPLEMENTATION_ENVIRONMENT_RUNTIME_EXECUTED_DELIVERABLE: `workflow_listing_filters[${WORKFLOW_LISTING_MODAL_IMPLEMENTATION_ENVIRONMENT_RUNTIME_EXECUTED_DELIVERABLE_LOCAL_STORAGE_KEY}]`,

  MY_TASKS: `my_tasks_filters[${MY_TASKS_LOCAL_STORAGE_KEY}]`,
  MY_ASSIGNMENTS: `my_assignments_filters[${MY_ASSIGNMENTS_LOCAL_STORAGE_KEY}]`,
};

// used to choose small or large tooltip
// small tooltip has a line-clamp set to 20, so it will show approx 700 characters
export const SMALL_TOOLTIP_CHARACTER_LENGTH = 700;

export const MAX_ATTACHMENT_SIZE = 1000 * 1024 * 1024; // (1 GB)

export const FILE_TYPES_WITH_THUMBNAIL = ['image/jpeg', 'image/pjpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp', 'image/x-ms-bmp', 'image/webp', 'image/svg+xml'];

export const fesCannotHaveTheSameNameErrorMessage = 'FEs cannot have the same name';

export const PLACEHOLDER_ROOT_ID = '00000000-0000-0000-0000-000000000000';

export const WEBVIEWER_ALLOWED_FILE_TYPES = ['pdf', 'png', 'jpg', 'tif', 'tiff', 'docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx'];

export const WEBVIEWER_IMAGE_TYPES = ['png', 'jpg', 'tif', 'tiff'];

export const FAKE_WEBVIEWER_TYPES = ['jpeg', 'gif', 'bmp', 'webp', 'svg', 'jpe', 'jfi', 'jif', 'jfif', 'csv'];

export const DOC_FILE_TYPES = ['docx', 'doc'];

export const XLS_FILE_TYPES = ['xlsx', 'xls'];

export const PPT_FILE_TYPES = ['ppt', 'pptx'];

export const CSV_FILE_TYPES = ['csv'];

export const EXECUTED_TEST_SCRIPT_DATA_TYPES = ['attachment', 'details-modal', 'deviation'];

export const TINYMCE_ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];

export const TINYMCE_ALL_IMAGE_TYPES = 'jpeg,jpg,png,gif,bmp,webp,svg,jpe,jfi,jif,jfif,tif,tiff';

export const CHUNK_SIZE_FOR_API_CALLS = 1000;

export const WEBSOCKET_CHANNELS = {
  USERS_NOTIFICATIONS: 'private-users.notifications',
  AUTOLOGOUT: 'users.sessions'
}

export const WEBSOCKET_EVENTS = {
  BROADCAST_NOTIFICATION_CREATED: 'notification',
  BROADCAST_NOTIFICATION_COUNTER_UPDATED: 'counted.updated',
  BROADCAST_NOTIFICATION_LOGOUT: 'sessions.expired',
  BROADCAST_REPORT_PROCESSING: 'report-processing',
  BROADCAST_REPORT_COMPLETED: 'report-completed',
  BROADCAST_REPORT_FAILED: 'report-failed'
}

export const PUSHER_EVENTS = {
  CONNECTION_ESTABLISHED: 'pusher:connection_established',
  SUBSCRIBE: 'pusher:subscribe',
}
export const MENU_SIZES = {
  expanded: '24.9rem',
  collapsed: '7.625rem'
};
const ADD_VIEW_ATTACHMENTS_MODAL_PAGINATION_LOCAL_STORAGE_KEY = 'AddViewAttachmentsModalPagination';

const ADMIN_USERS_PAGINATION_LOCAL_STORAGE_KEY = 'AdminUsersPagination';
const ADMIN_COMPANIES_PAGINATION_LOCAL_STORAGE_KEY = 'AdminCompaniesPagination';
const ADMIN_COMPANIES_PARENT_PAGINATION_LOCAL_STORAGE_KEY = 'AdminCompaniesParentPagination';
const ADMIN_COMPANIES_CHILD_PAGINATION_LOCAL_STORAGE_KEY = 'AdminCompaniesChildPagination';
const ADMIN_LICENSES_DASHBOARD_PAGINATION_LOCAL_STORAGE_KEY = 'AdminLicensesDashboardPagination';
const ADMIN_MARKETPLACE_SYSTEMS_PAGINATION_LOCAL_STORAGE_KEY = 'AdminMarketplaceSystemsPagination';

const ADMIN_AUDIT_TRAIL_FULL_PAGINATION_LOCAL_STORAGE_KEY = 'AdminAuditTrailFullPagination';
const ADMIN_AUDIT_TRAIL_ADMIN_PAGINATION_LOCAL_STORAGE_KEY = 'AdminAuditTrailAdminPagination';
const ADMIN_AUDIT_TRAIL_COMPANY_DETAILS_PAGINATION_LOCAL_STORAGE_KEY = 'AdminAuditTrailCompanyDetailsPagination';
const ADMIN_AUDIT_TRAIL_COMPANY_USERS_PAGINATION_LOCAL_STORAGE_KEY = 'AdminAuditTrailCompanyUsersPagination';
const ADMIN_AUDIT_TRAIL_COMPANY_SETTINGS_PAGINATION_LOCAL_STORAGE_KEY = 'AdminAuditTrailCompanySettingsPagination';
const ADMIN_AUDIT_TRAIL_POD_MANAGEMENT_PAGINATION_LOCAL_STORAGE_KEY = 'AdminAuditTrailPodManagementPagination';
const ADMIN_AUDIT_TRAIL_MARKETPLACE_PAGINATION_LOCAL_STORAGE_KEY = 'AdminAuditTrailMarketplacePagination';
const ADMIN_AUDIT_TRAIL_GLOBAL_SYSTEMS_PAGINATION_LOCAL_STORAGE_KEY = 'AdminAuditTrailGlobalSystemsPagination';
const ADMIN_AUDIT_TRAIL_CMS_DASHBOARD_PAGINATION_LOCAL_STORAGE_KEY = 'AdminAuditTrailCMSDashboardPagination';

const AUDIT_TRAIL_FULL_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailFullPagination';
const AUDIT_TRAIL_DOCUMENTS_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailDocumentsPagination';
const AUDIT_TRAIL_TEMPLATES_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailTemplatesPagination';
const AUDIT_TRAIL_WORKFLOWS_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailWorkflowsPagination';
const AUDIT_TRAIL_COMPANY_USERS_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailCompanyUsersPagination';
const AUDIT_TRAIL_PERMISSIONS_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailPermissionsPagination';
const AUDIT_TRAIL_COMPANY_SETTINGS_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailCompanySettingsPagination';
const AUDIT_TRAIL_LOGIN_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailLoginPagination';
const AUDIT_TRAIL_USER_GROUPS_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailUserGroupsPagination';
const AUDIT_TRAIL_FILTER_TAGS_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailFilterTagsPagination';
const AUDIT_TRAIL_MODAL_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailModalPagination';
const AUDIT_TRAIL_IA_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailImpactAssessmentsPagination';
const AUDIT_TRAIL_COMPANY_DETAILS_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailCompanyDetailsPagination';
const AUDIT_TRAIL_COMPANY_CONTACTS_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailCompanyContactsPagination';
const AUDIT_TRAIL_DEPARTMENTS_SITES_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailDepartmentsSitesPagination';
const AUDIT_TRAIL_PARENT_CHILD_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailParentChildPagination';
const AUDIT_TRAIL_COMPANY_SYSTEMS_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailCompanySystemsPagination';
const AUDIT_TRAIL_LICENSES_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailLicensesPagination';
const AUDIT_TRAIL_PREFIXES_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailPrefixesPagination';
const AUDIT_TRAIL_SIGNATURE_SETTINGS_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailSignatureSettingsPagination';
const AUDIT_TRAIL_REPORTS_EXPORTS_PAGINATION_LOCAL_STORAGE_KEY = 'AuditTrailReportsExportsPagination'

const BULK_SHARE_TEMPLATES_PAGINATION_LOCAL_STORAGE_KEY = 'BulkShareTemplatesPagination';
const BULK_SHARE_WORKSPACE_PAGINATION_LOCAL_STORAGE_KEY = 'BulkShareWorkspacePagination';
const BULK_TRANSFER_TEMPLATES_PAGINATION_LOCAL_STORAGE_KEY = 'BulkTransferTemplatesPagination';
const BULK_TRANSFER_WORKSPACE_PAGINATION_LOCAL_STORAGE_KEY = 'BulkTransferWorkspacePagination';

const COMPANY_ADMIN_CONTACTS_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminContactsPagination';
const COMPANY_ADMIN_DEPARTMENTS_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminDepartmentsPagination';
const COMPANY_ADMIN_SITES_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminSitesPagination';
const COMPANY_ADMIN_PARENT_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminParentPagination';
const COMPANY_ADMIN_CHILD_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminChildPagination';
const COMPANY_ADMIN_ACCESS_LIST_MODAL_TEMPLATES_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminAccessListModalTemplatesPagination';
const COMPANY_ADMIN_ACCESS_LIST_MODAL_WORKFLOWS_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminAccessListModalWorkflowsPagination';
const COMPANY_ADMIN_USERS_LISTING_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminUsersListingPagination';
const COMPANY_ADMIN_PENDING_USERS_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminPendingSsoUsersPagination';
const COMPANY_ADMIN_USERS_BULK_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminUsersBulkPagination';
const COMPANY_ADMIN_FILTER_TAGS_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminFilterTagsPagination';
const COMPANY_ADMIN_REPORTING_LOGS_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminReportingLogsPagination';
const COMPANY_ADMIN_PREFIX_SETTINGS_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminPrefixSettingsPagination';
const COMPANY_ADMIN_SIGNATURE_MEANING_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminSignatureMeaningPagination';
const COMPANY_ADMIN_SIGNATURE_ROLE_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminSignatureRolePagination';
const COMPANY_ADMIN_SYSTEMS_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminSystemsPagination';
const COMPANY_ADMIN_LICENSES_PAGINATION_LOCAL_STORAGE_KEY = 'CompanyAdminLicensesPagination';

const GROUPS_PAGINATION_LOCAL_STORAGE_KEY = 'GroupsPagination';

const HISTORY_APPROVAL_REQUESTS_PAGINATION_LOCAL_STORAGE_KEY = 'HistoryApprovalRequestsPagination';

const IMPACT_ASSESSMENT_CONFIGURATION_PAGINATION_LOCAL_STORAGE_KEY = 'ImpactAssessmentConfigurationPagination';

const MY_TASKS_PAGINATION_LOCAL_STORAGE_KEY = 'MyTasksPagination';
const MY_ASSIGNMENTS_PAGINATION_LOCAL_STORAGE_KEY = 'MyAssignmentsPagination';

const PACKAGE_ADD_ASSET_TEMPLATES_PAGINATION_LOCAL_STORAGE_KEY = 'PackageAddAssetTemplatesPagination';
const PACKAGE_ADD_ASSET_WORKFLOWS_PAGINATION_LOCAL_STORAGE_KEY = 'PackageAddAssetWorkflowsPagination';

const PROFILE_API_KEYS_PAGINATION_LOCAL_STORAGE_KEY = 'ProfileApiKeysPagination';

const ADMIN_REPORTS_LICENSES_PAGINATION_LOCAL_STORAGE_KEY = 'AdminReportsLicensesPagination';
const ADMIN_REPORTS_USAGES_PAGINATION_LOCAL_STORAGE_KEY = 'AdminReportsUsagesPagination';
const ADMIN_REPORTS_USERS_PAGINATION_LOCAL_STORAGE_KEY = 'AdminReportsUsersPagination';

const REPORTS_SYSTEMS_PAGINATION_LOCAL_STORAGE_KEY = 'ReportsSystemsPagination';
const REPORTS_WORKFLOWS_PAGINATION_LOCAL_STORAGE_KEY = 'ReportsWorkflowsPagination';
const REPORTS_WORKFLOW_DELIVERABLES_PAGINATION_LOCAL_STORAGE_KEY = 'ReportsWorkflowDeliverablesPagination';
const REPORTS_TEST_SCRIPT_EXECUTION_PAGINATION_LOCAL_STORAGE_KEY = 'ReportsTestScriptExecutionPagination';
const REPORTS_TASKS_PAGINATION_LOCAL_STORAGE_KEY = 'ReportsTasksPagination';
const REPORTS_DEVIATIONS_PAGINATION_LOCAL_STORAGE_KEY = 'ReportsDeviationsPagination';
const REPORTS_PROJECT_MANAGEMENT_PAGINATION_LOCAL_STORAGE_KEY = 'ReportsProjectManagementPagination';
const REPORTS_REVIEWS_PAGINATION_LOCAL_STORAGE_KEY = 'ReportsReviewsPagination';
const REPORTS_PERIODIC_REVIEWS_PAGINATION_LOCAL_STORAGE_KEY = 'ReportsPeriodicReviewsPagination';


const TABLE_PAGINATION_LOCAL_STORAGE_KEY = 'TablePagination';
const TABLE_PREVIEW_PAGINATION_LOCAL_STORAGE_KEY = 'TablePreviewPagination';

const TEMPLATE_PAGINATION_LOCAL_STORAGE_KEY = 'TemplatePagination';

const TRACE_MATRIX_LINKS_WORKFLOW_SELECTOR_PAGINATION_LOCAL_STORAGE_KEY = 'TraceMatrixLinksWorkflowSelectorPagination';

const USERS_LISTING_MODAL_PAGINATION_LOCAL_STORAGE_KEY = 'UsersListingModalPagination';

const WORKFLOW_LISTING_MODAL_PAGINATION_LOCAL_STORAGE_KEY = 'WorkflowListingModalPagination';
const WORKSPACE_PAGINATION_LOCAL_STORAGE_KEY = 'WorkspacePagination';

const BULK_ASSIGN_WORKSPACE_PAGINATION_LOCAL_STORAGE_KEY = 'BulkAssignWorkspacePagination';

export const PAGINATION_LOCAL_STORAGE_KEY = {
  ADD_VIEW_ATTACHMENTS_MODAL: `add_view_attachments_modal_pagination[${ADD_VIEW_ATTACHMENTS_MODAL_PAGINATION_LOCAL_STORAGE_KEY}]`,

  ADMIN_USERS: `admin_users_pagination[${ADMIN_USERS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_COMPANIES: `admin_companies_pagination[${ADMIN_COMPANIES_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_COMPANIES_PARENT: `admin_companies_parent_pagination[${ADMIN_COMPANIES_PARENT_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_COMPANIES_CHILD: `admin_companies_child_pagination[${ADMIN_COMPANIES_CHILD_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_LICENSES_DASHBOARD: `admin_licenses_dashboard_pagination[${ADMIN_LICENSES_DASHBOARD_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_MARKETPLACE_SYSTEMS: `admin_marketplace_systems_pagination[${ADMIN_MARKETPLACE_SYSTEMS_PAGINATION_LOCAL_STORAGE_KEY}]`,

  ADMIN_AUDIT_TRAIL_FULL: `audit_trail_pagination[${ADMIN_AUDIT_TRAIL_FULL_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_ADMIN: `audit_trail_pagination[${ADMIN_AUDIT_TRAIL_ADMIN_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_COMPANY_DETAILS: `audit_trail_pagination[${ADMIN_AUDIT_TRAIL_COMPANY_DETAILS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_COMPANY_USERS: `audit_trail_pagination[${ADMIN_AUDIT_TRAIL_COMPANY_USERS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_COMPANY_SETTINGS: `audit_trail_pagination[${ADMIN_AUDIT_TRAIL_COMPANY_SETTINGS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_POD_MANAGEMENT: `audit_trail_pagination[${ADMIN_AUDIT_TRAIL_POD_MANAGEMENT_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_MARKETPLACE: `audit_trail_pagination[${ADMIN_AUDIT_TRAIL_MARKETPLACE_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_GLOBAL_SYSTEMS: `audit_trail_pagination[${ADMIN_AUDIT_TRAIL_GLOBAL_SYSTEMS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_AUDIT_TRAIL_CMS_DASHBOARD: `audit_trail_pagination[${ADMIN_AUDIT_TRAIL_CMS_DASHBOARD_PAGINATION_LOCAL_STORAGE_KEY}]`,

  AUDIT_TRAIL_FULL: `audit_trail_pagination[${AUDIT_TRAIL_FULL_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_DOCUMENTS: `audit_trail_pagination[${AUDIT_TRAIL_DOCUMENTS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_TEMPLATES: `audit_trail_pagination[${AUDIT_TRAIL_TEMPLATES_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_WORKFLOWS: `audit_trail_pagination[${AUDIT_TRAIL_WORKFLOWS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_COMPANY_USERS: `audit_trail_pagination[${AUDIT_TRAIL_COMPANY_USERS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_PERMISSIONS: `audit_trail_pagination[${AUDIT_TRAIL_PERMISSIONS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_COMPANY_SETTINGS: `audit_trail_pagination[${AUDIT_TRAIL_COMPANY_SETTINGS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_LOGIN: `audit_trail_pagination[${AUDIT_TRAIL_LOGIN_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_USER_GROUPS: `audit_trail_pagination[${AUDIT_TRAIL_USER_GROUPS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_FILTER_TAGS: `audit_trail_pagination[${AUDIT_TRAIL_FILTER_TAGS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_MODAL: `audit_trail_pagination[${AUDIT_TRAIL_MODAL_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_IA: `audit_trail_pagination[${AUDIT_TRAIL_IA_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_COMPANY_DETAILS: `audit_trail_pagination[${AUDIT_TRAIL_COMPANY_DETAILS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_COMPANY_CONTACTS: `audit_trail_pagination[${AUDIT_TRAIL_COMPANY_CONTACTS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_DEPARTMENTS_SITES: `audit_trail_pagination[${AUDIT_TRAIL_DEPARTMENTS_SITES_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_PARENT_CHILD: `audit_trail_pagination[${AUDIT_TRAIL_PARENT_CHILD_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_COMPANY_SYSTEMS: `audit_trail_pagination[${AUDIT_TRAIL_COMPANY_SYSTEMS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_LICENSES: `audit_trail_pagination[${AUDIT_TRAIL_LICENSES_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_PREFIXES: `audit_trail_pagination[${AUDIT_TRAIL_PREFIXES_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_SIGNATURE_SETTINGS: `audit_trail_pagination[${AUDIT_TRAIL_SIGNATURE_SETTINGS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  AUDIT_TRAIL_REPORTS_EXPORTS: `audit_trail_pagination[${AUDIT_TRAIL_REPORTS_EXPORTS_PAGINATION_LOCAL_STORAGE_KEY}]`,

  BULK_SHARE_TEMPLATES: `bulk_share_transfer_pagination[${BULK_SHARE_TEMPLATES_PAGINATION_LOCAL_STORAGE_KEY}]`,
  BULK_SHARE_WORKSPACE: `bulk_share_transfer_pagination[${BULK_SHARE_WORKSPACE_PAGINATION_LOCAL_STORAGE_KEY}]`,
  BULK_TRANSFER_TEMPLATES: `bulk_share_transfer_pagination[${BULK_TRANSFER_TEMPLATES_PAGINATION_LOCAL_STORAGE_KEY}]`,
  BULK_TRANSFER_WORKSPACE: `bulk_share_transfer_pagination[${BULK_TRANSFER_WORKSPACE_PAGINATION_LOCAL_STORAGE_KEY}]`,

  COMPANY_ADMIN_CONTACTS: `company_admin_contacts_pagination[${COMPANY_ADMIN_CONTACTS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_DEPARTMENTS: `company_admin_departments_pagination[${COMPANY_ADMIN_DEPARTMENTS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_SITES: `company_admin_sites_pagination[${COMPANY_ADMIN_SITES_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_PARENT: `company_admin_parent_pagination[${COMPANY_ADMIN_PARENT_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_CHILD: `company_admin_child_pagination[${COMPANY_ADMIN_CHILD_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_USERS_ACCESS_LIST_MODAL_TEMPLATES: `company_admin_users_access_list_modal_templates_pagination[${COMPANY_ADMIN_ACCESS_LIST_MODAL_TEMPLATES_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_USERS_ACCESS_LIST_MODAL_WORKFLOWS: `company_admin_users_access_list_modal_workflows_pagination[${COMPANY_ADMIN_ACCESS_LIST_MODAL_WORKFLOWS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_USERS_LISTING: `company_admin_users_listing_pagination[${COMPANY_ADMIN_USERS_LISTING_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_USERS_LISTING_PENDING_SSO_USERS: `company_admin_users_pending_sso_users_pagination[${COMPANY_ADMIN_PENDING_USERS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_USERS_BULK: `company_admin_users_bulk_pagination[${COMPANY_ADMIN_USERS_BULK_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_FILTER_TAGS: `company_admin_filter_tags_pagination[${COMPANY_ADMIN_FILTER_TAGS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_REPORTING_LOGS: `company_admin_reporting_logs_pagination[${COMPANY_ADMIN_REPORTING_LOGS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_PREFIX_SETTINGS: `company_admin_prefix_settings_pagination[${COMPANY_ADMIN_PREFIX_SETTINGS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_SIGNATURE_MEANING: `company_admin_signature_meaning_pagination[${COMPANY_ADMIN_SIGNATURE_MEANING_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_SIGNATURE_ROLE: `company_admin_signature_role_pagination[${COMPANY_ADMIN_SIGNATURE_ROLE_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_SYSTEMS: `company_admin_systems_pagination[${COMPANY_ADMIN_SYSTEMS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  COMPANY_ADMIN_LICENSES: `company_admin_licenses_pagination[${COMPANY_ADMIN_LICENSES_PAGINATION_LOCAL_STORAGE_KEY}]`,

  GROUPS: `groups_pagination[${GROUPS_PAGINATION_LOCAL_STORAGE_KEY}]`,

  IMPACT_ASSESSMENT_CONFIGURATION: `impact_assessment_configuration_pagination[${IMPACT_ASSESSMENT_CONFIGURATION_PAGINATION_LOCAL_STORAGE_KEY}]`,

  MARKETPLACE_HISTORY_APPROVAL_REQUESTS: `marketplace_pagination[${HISTORY_APPROVAL_REQUESTS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  MARKETPLACE_PACKAGE_ADD_ASSET_TEMPLATES: `marketplace_package_add_asset_pagination[${PACKAGE_ADD_ASSET_TEMPLATES_PAGINATION_LOCAL_STORAGE_KEY}]`,
  MARKETPLACE_PACKAGE_ADD_ASSET_WORKFLOWS: `marketplace_package_add_asset_pagination[${PACKAGE_ADD_ASSET_WORKFLOWS_PAGINATION_LOCAL_STORAGE_KEY}]`,

  MY_TASKS: `my_tasks_pagination[${MY_TASKS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  MY_ASSIGNMENTS: `my_assignments_pagination[${MY_ASSIGNMENTS_PAGINATION_LOCAL_STORAGE_KEY}]`,

  PROFILE_API_KEYS: `profile_api_keys_pagination[${PROFILE_API_KEYS_PAGINATION_LOCAL_STORAGE_KEY}]`,

  ADMIN_REPORTS_LICENSES: `reports_pagination[${ADMIN_REPORTS_LICENSES_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_REPORTS_USAGES: `reports_pagination[${ADMIN_REPORTS_USAGES_PAGINATION_LOCAL_STORAGE_KEY}]`,
  ADMIN_REPORTS_USERS: `reports_pagination[${ADMIN_REPORTS_USERS_PAGINATION_LOCAL_STORAGE_KEY}]`,

  REPORTS_SYSTEMS: `reports_pagination[${REPORTS_SYSTEMS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  REPORTS_WORKFLOWS: `reports_pagination[${REPORTS_WORKFLOWS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  REPORTS_WORKFLOW_DELIVERABLES: `reports_pagination[${REPORTS_WORKFLOW_DELIVERABLES_PAGINATION_LOCAL_STORAGE_KEY}]`,
  REPORTS_TEST_SCRIPT_EXECUTION: `reports_pagination[${REPORTS_TEST_SCRIPT_EXECUTION_PAGINATION_LOCAL_STORAGE_KEY}]`,
  REPORTS_DEVIATIONS: `reports_pagination[${REPORTS_DEVIATIONS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  REPORTS_TASKS: `reports_pagination[${REPORTS_TASKS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  REPORTS_PROJECT_MANAGEMENT: `reports_pagination[${REPORTS_PROJECT_MANAGEMENT_PAGINATION_LOCAL_STORAGE_KEY}]`,
  REPORTS_REVIEWS: `reports_pagination[${REPORTS_REVIEWS_PAGINATION_LOCAL_STORAGE_KEY}]`,
  REPORTS_PERIODIC_REVIEWS: `reports_pagination[${REPORTS_PERIODIC_REVIEWS_PAGINATION_LOCAL_STORAGE_KEY}]`,

  TABLE: `table_pagination[${TABLE_PAGINATION_LOCAL_STORAGE_KEY}]`,
  TABLE_PREVIEW: `table_preview_pagination[${TABLE_PREVIEW_PAGINATION_LOCAL_STORAGE_KEY}]`,

  TEMPLATE: `pagination[${TEMPLATE_PAGINATION_LOCAL_STORAGE_KEY}]`,

  TRACE_MATRIX_LINKS_WORKFLOW_SELECTOR: `trace_matrix_links_pagination[${TRACE_MATRIX_LINKS_WORKFLOW_SELECTOR_PAGINATION_LOCAL_STORAGE_KEY}]`,

  USERS_LISTING_MODAL: `users_listing_modal_pagination[${USERS_LISTING_MODAL_PAGINATION_LOCAL_STORAGE_KEY}]`,

  WORKFLOW_LISTING_MODAL: `workflow_listing_modal_pagination[${WORKFLOW_LISTING_MODAL_PAGINATION_LOCAL_STORAGE_KEY}]`,

  WORKSPACE: `pagination[${WORKSPACE_PAGINATION_LOCAL_STORAGE_KEY}]`,


  BULK_ASSIGN_WORKSPACE: `bulk_assign_pagination[${BULK_ASSIGN_WORKSPACE_PAGINATION_LOCAL_STORAGE_KEY}]`,
};

export const PAGINATION_OPTIONS = [
  {
    value: 5,
    label: '5'
  },
  {
    value: 10,
    label: '10'
  },
  {
    value: 25,
    label: '25'
  },
  {
    value: 50,
    label: '50'
  },
  {
    value: 100,
    label: '100'
  },
]

export const REPORTS_LISTING = [
  {
    key: ReportsTitleURLKey.Systems,
    title: ReportTitle.Systems,
    columns: ['system', 'system-owner', 'implementation-date', 'periodic-review-date', 'number-of-releases', 'system-risk', 'published-templates', 'approved-documents', 'active-workflows', 'approved-workflows'],
    columnsMapping: [
      {
        key: 'system',
        title: 'System',
        rowDataKey: 'system_name',
      },
      {
        key: 'system-owner',
        title: 'System Owner',
        rowDataKey: 'system_owner'
      },
      {
        key: 'implementation-date',
        title: 'Implementation Date',
        rowDataKey: 'implementation_date'
      },
      {
        key: 'periodic-review-date',
        title: 'Periodic Review Date',
        rowDataKey: 'periodic_review_date'
      },
      {
        key: 'number-of-releases',
        title: 'Number of Releases',
        rowDataKey: 'releases_number',
      },
      {
        key: 'system-risk',
        title: 'System Risk',
        rowDataKey: 'system_risk',
      },
      {
        key: 'published-templates',
        title: 'Published Templates',
        rowDataKey: 'published_templates',
      },
      {
        key: 'approved-documents',
        title: 'Approved Documents',
        rowDataKey: 'approved_documents',
      },
      {
        key: 'active-workflows',
        title: 'Active Workflows',
        isLink: true,
        tooltip: 'This includes workflows with status Draft, Waiting for Signature. The number of workflows displayed is for the total number of workflows in this system, disregarding if you have ACL or not.',
        rowDataKey: 'active_workflows',
      },
      {
        key: 'approved-workflows',
        title: 'Approved Workflows',
        rowDataKey: 'approved_workflows',
      },
    ],
    sortDefault: 'periodic_review_date',
    sortDirection: 'desc',
    paginationKey: PAGINATION_LOCAL_STORAGE_KEY.REPORTS_SYSTEMS,
    filterKey: FILTERS_LOCAL_STORAGE_KEY.REPORTS_SYSTEMS,
    savedViewsKey: 'reports_systems',
    searchFilterKey: 'Search System'
  },
  {
    key: ReportsTitleURLKey.Workflows,
    title: ReportTitle.Workflows,
    columns: ['id', 'title', 'workflow-status', 'workflow-type', 'workflow-phase', 'number-of-changes', 'risk-assessment', 'risk-impact', 'system', 'creation-date', 'last-updated', 'completion-date', 'duration', 'approved-documents'],
    columnsMapping: [
      {
        key: 'id',
        title: 'ID',
        rowDataKey: 'workflow_display_label'
      },
      {
        key: 'title',
        title: 'Title',
        rowDataKey: 'workflow_title'
      },
      {
        key: 'workflow-status',
        title: 'Workflow Status',
        rowDataKey: 'workflow_status'
      },
      {
        key: 'workflow-type',
        title: 'Workflow Type',
        rowDataKey: 'workflow_type'
      },
      {
        key: 'workflow-phase',
        title: 'Workflow Phase',
        rowDataKey: 'workflow_phase'
      },
      {
        key: 'number-of-changes',
        title: 'Number of Changes',
        rowDataKey: 'description_changes_count'
      },
      {
        key: 'risk-assessment',
        title: 'Risk Assessment',
        rowDataKey: 'risk_assessment',
        isNotSortable: true
      },
      {
        key: 'risk-impact',
        title: 'Risk Impact',
        rowDataKey: 'risk_impact',
        isNotSortable: true
      },
      {
        key: 'system',
        title: 'System',
        rowDataKey: 'system_name'
      },
      {
        key: 'creation-date',
        title: 'Creation Date',
        rowDataKey: 'creation_date'
      },
      {
        key: 'last-updated',
        title: 'Last Updated',
        rowDataKey: 'last_updated'
      },
      {
        key: 'completion-date',
        title: 'Completion Date',
        rowDataKey: 'completion_date'
      },
      {
        key: 'duration',
        title: 'Duration',
        rowDataKey: 'duration'
      },
      {
        key: 'approved-documents',
        title: 'Approved Documents',
        isLink: true,
        rowDataKey: 'approved_documents',
      },
    ],
    sortDefault: 'last_updated',
    sortDirection: 'desc',
    paginationKey: PAGINATION_LOCAL_STORAGE_KEY.REPORTS_WORKFLOWS,
    filterKey: FILTERS_LOCAL_STORAGE_KEY.REPORTS_WORKFLOWS,
    savedViewsKey: 'reports_workflows',
    searchFilterKey: 'Search Title'
  },
  {
    key: ReportsTitleURLKey.WorkflowDeliverables,
    title: ReportTitle.WorkflowDeliverables,
    columns: ['deliverable', 'deliverable-type', 'id', 'title', 'latest-version', 'owner', 'reviewers', 'approvers', 'approval-date'],
    columnsMapping: [
      {
        key: 'deliverable',
        title: 'Deliverable',
        rowDataKey: 'deliverable'
      },
      {
        key: 'deliverable-type',
        title: 'Deliverable Type',
        rowDataKey: 'deliverable_type'
      },
      {
        key: 'id',
        title: 'ID',
        rowDataKey: 'workflow_display_label'
      },
      {
        key: 'title',
        title: 'Title',
        rowDataKey: 'workflow_title'
      },
      {
        key: 'latest-version',
        title: 'Latest Version',
        rowDataKey: 'workflow_latest_version'
      },
      {
        key: 'owner',
        title: 'Owner',
        rowDataKey: 'owner',
        isNotSortable: true
      },
      {
        key: 'reviewers',
        title: 'Reviewers',
        rowDataKey: 'reviewers',
        isNotSortable: true
      },
      {
        key: 'approvers',
        title: 'Approvers',
        rowDataKey: 'approvers',
        isNotSortable: true
      },
      {
        key: 'approval-date',
        title: 'Approval Date',
        rowDataKey: 'approval_date'
      }
    ],
    sortDefault: 'workflow_display_label',
    sortDirection: 'desc',
    paginationKey: PAGINATION_LOCAL_STORAGE_KEY.REPORTS_WORKFLOW_DELIVERABLES,
    filterKey: FILTERS_LOCAL_STORAGE_KEY.REPORTS_WORKFLOW_DELIVERABLES,
    savedViewsKey: 'reports_deliverables',
    searchFilterKey: 'Search Deliverable'
  },
  {
    key: ReportsTitleURLKey.TestScripts,
    title: ReportTitle.TestScripts,
    columns: ['id', 'title', 'status', 'prefix', 'creation-date', 'owner', 'execution-start-date', 'testers', 'duration', 'linked-requirements', 'steps', 'pass', 'n/a', 'fail', 'linked-deviations', 'run', 'completion-date'],
    columnsMapping: [
      {
        key: 'id',
        title: 'ID',
        rowDataKey: 'workflow_display_label'
      },
      {
        key: 'title',
        title: 'Title',
        rowDataKey: 'workflow_title',
        isLink: true,
      },
      {
        key: 'status',
        title: 'Status',
        rowDataKey: 'workflow_status'
      },
      {
        key: 'prefix',
        title: 'Prefix',
        rowDataKey: 'workflow_prefix'
      },
      {
        key: 'creation-date',
        title: 'Creation Date',
        rowDataKey: 'creation_date'
      },
      {
        key: 'owner',
        title: 'Owner',
        rowDataKey: 'owner',
        isNotSortable: true
      },
      {
        key: 'execution-start-date',
        title: 'Execution Start Date',
        rowDataKey: 'execution_start_date'
      },
      {
        key: 'testers',
        title: 'Testers',
        rowDataKey: 'testers',
        isNotSortable: true
      },
      {
        key: 'duration',
        title: 'Duration',
        rowDataKey: 'duration'
      },
      {
        key: 'linked-requirements',
        title: 'Linked Requirements',
        rowDataKey: 'linked_requirements_count'
      },
      {
        key: 'steps',
        title: 'Steps',
        rowDataKey: 'steps_count'
      },
      {
        key: 'pass',
        title: 'Pass',
        rowDataKey: 'passes_count'
      },
      {
        key: 'n/a',
        title: 'N/A',
        rowDataKey: 'na_count'
      },
      {
        key: 'fail',
        title: 'Fail',
        rowDataKey: 'fails_count'
      },
      {
        key: 'linked-deviations',
        title: 'Linked Deviations',
        rowDataKey: 'linked_deviations_count',
        isLink: true,
      },
      {
        key: 'run',
        title: 'Run',
        rowDataKey: 'runs_count'
      },
      {
        key: 'completion-date',
        title: 'Completion Date',
        rowDataKey: 'completion_date'
      },
    ],
    sortDefault: 'execution_start_date',
    sortDirection: 'desc',
    paginationKey: PAGINATION_LOCAL_STORAGE_KEY.REPORTS_TEST_SCRIPT_EXECUTION,
    filterKey: FILTERS_LOCAL_STORAGE_KEY.REPORTS_TEST_SCRIPT_EXECUTION,
    savedViewsKey: 'reports_test_script_execution',
    searchFilterKey: 'Search Title'
  },
  {
    key: ReportsTitleURLKey.Deviations,
    title: ReportTitle.Deviations,
    columns: ['id', 'title', 'linked-deviation-type', 'executed-ts-id', 'executed-ts-title', 'system', 'reviewers', 'approvers', 'owner', 'creation-date', 'approval-date'],
    columnsMapping: [
      {
        key: 'id',
        title: 'Deviation ID',
        rowDataKey: 'linked_display_label'
      },
      {
        key: 'title',
        title: 'Deviation Title',
        rowDataKey: 'linked_title',
        isLink: true,
        isLinkDisabledBasedOnAnotherColumn: 'linked_type',
        isLinkDisabledBasedOnAnotherColumnValue: 'Linked document',
        // If the row value of isLinkDisabledBasedOnAnotherColumn is different than the
        // isLinkDisabledBasedOnAnotherColumnValue ('Linked document' in our case) then the link will be disabled.
      },
      {
        key: 'linked-deviation-type',
        title: 'Deviation Type',
        rowDataKey: 'linked_type',
      },
      {
        key: 'executed-ts-id',
        title: 'Executed TS ID',
        rowDataKey: 'workflow_display_label'
      },
      {
        key: 'executed-ts-title',
        title: 'Executed TS Title',
        rowDataKey: 'workflow_title'
      },
      {
        key: 'system',
        title: 'System',
        rowDataKey: 'system_name'
      },
      {
        key: 'reviewers',
        title: 'Reviewers',
        rowDataKey: 'reviewers',
        isNotSortable: true
      },
      {
        key: 'approvers',
        title: 'Approvers',
        rowDataKey: 'approvers',
        isNotSortable: true
      },
      {
        key: 'owner',
        title: 'Owner',
        rowDataKey: 'owner',
        isNotSortable: true
      },
      {
        key: 'creation-date',
        title: 'Creation Date',
        rowDataKey: 'creation_date'
      },
      {
        key: 'approval-date',
        title: 'Approval Date',
        rowDataKey: 'approval_date'
      }
    ],
    sortDefault: 'workflow_display_label',
    sortDirection: 'desc',
    paginationKey: PAGINATION_LOCAL_STORAGE_KEY.REPORTS_DEVIATIONS,
    filterKey: FILTERS_LOCAL_STORAGE_KEY.REPORTS_DEVIATIONS,
    savedViewsKey: 'reports_deviations',
    searchFilterKey: 'Search Deviation Title'
  },
  {
    key: ReportsTitleURLKey.Tasks,
    title: ReportTitle.Tasks,
    columns: ['user', 'id', 'title', 'system', 'task-type', 'task-status', 'task-assignation-date', 'task-start-date', 'task-due-date', 'task-completion-date', 'duration', 'delay'],
    columnsMapping: [
      {
        key: 'user',
        title: 'User',
        rowDataKey: 'user',
        isNotSortable: true
      },
      {
        key: 'id',
        title: 'ID',
        rowDataKey: 'workflow_display_label'
      },
      {
        key: 'title',
        title: 'Title',
        rowDataKey: 'workflow_title'
      },
      {
        key: 'system',
        title: 'System',
        rowDataKey: 'system_name'
      },
      {
        key: 'task-type',
        title: 'Task Type',
        rowDataKey: 'task_type'
      },
      {
        key: 'task-status',
        title: 'Task Status',
        rowDataKey: 'task_status'
      },
      {
        key: 'task-assignation-date',
        title: 'Task Assignation Date',
        rowDataKey: 'task_assignation_date'
      },
      {
        key: 'task-start-date',
        title: 'Task Start Date',
        rowDataKey: 'task_start_date'
      },
      {
        key: 'task-due-date',
        title: 'Task Due Date',
        rowDataKey: 'task_due_date'
      },
      {
        key: 'task-completion-date',
        title: 'Task Completion Date',
        rowDataKey: 'task_completion_date'
      },
      {
        key: 'duration',
        title: 'Duration',
        rowDataKey: 'duration'
      },
      {
        key: 'delay',
        title: 'Delay',
        rowDataKey: 'delay'
      },
    ],
    sortDefault: 'task_due_date',
    sortDirection: 'desc',
    paginationKey: PAGINATION_LOCAL_STORAGE_KEY.REPORTS_TASKS,
    filterKey: FILTERS_LOCAL_STORAGE_KEY.REPORTS_TASKS,
    savedViewsKey: 'reports_tasks',
    searchFilterKey: 'Search Title'
  },
  {
    key: ReportsTitleURLKey.ProjectManagement,
    title: ReportTitle.ProjectManagement,
    columns: ['prefix', 'filter-tags', 'id', 'title', 'status', 'system', 'owner', 'creation-date', 'tester', 'reviewer', 'review-cycles', 'approver', 'completion-date'],
    columnsMapping: [
      {
        key: 'prefix',
        title: 'Prefix',
        rowDataKey: 'workflow_prefix'
      },
      {
        key: 'filter-tags',
        title: 'Filter Tags',
        rowDataKey: 'filterTags',
        isNotSortable: true
      },
      {
        key: 'id',
        title: 'ID',
        rowDataKey: 'workflow_display_label'
      },
      {
        key: 'title',
        title: 'Title',
        rowDataKey: 'workflow_title',
        isLink: true,
      },
      {
        key: 'status',
        title: 'Status',
        rowDataKey: 'workflow_status'
      },
      {
        key: 'system',
        title: 'System',
        rowDataKey: 'system_name'
      },
      {
        key: 'owner',
        title: 'Owner',
        rowDataKey: 'owner',
        isNotSortable: true
      },
      {
        key: 'creation-date',
        title: 'Creation Date',
        rowDataKey: 'creation_date'
      },
      {
        key: 'tester',
        title: 'Testers',
        rowDataKey: 'testers',
        isNotSortable: true
      },
      {
        key: 'reviewer',
        title: 'Reviewers',
        rowDataKey: 'reviewers',
        isNotSortable: true
      },
      {
        key: 'review-cycles',
        title: 'Review Cycles',
        rowDataKey: 'review_cycles'
      },
      {
        key: 'approver',
        title: 'Approvers',
        rowDataKey: 'approvers',
        isNotSortable: true
      },
      {
        key: 'completion-date',
        title: 'Completion Date',
        rowDataKey: 'completion_date'
      },
    ],
    sortDefault: 'creation_date',
    sortDirection: 'desc',
    paginationKey: PAGINATION_LOCAL_STORAGE_KEY.REPORTS_PROJECT_MANAGEMENT,
    filterKey: FILTERS_LOCAL_STORAGE_KEY.REPORTS_PROJECT_MANAGEMENT,
    savedViewsKey: 'reports_project_management',
    searchFilterKey: 'Search Title'
  },
  {
    key: ReportsTitleURLKey.Reviews,
    title: ReportTitle.Reviews,
    columns: ['id', 'title', 'review-cycle', 'number-of-comments', 'start-date', 'completion-date', 'duration'],
    columnsMapping: [
      {
        key: 'id',
        title: 'ID',
        rowDataKey: 'workflow_display_label'
      },
      {
        key: 'title',
        title: 'Title',
        rowDataKey: 'workflow_title'
      },
      {
        key: 'review-cycle',
        title: 'Review Cycle',
        rowDataKey: 'review_cycles'
      },
      {
        key: 'number-of-comments',
        title: 'Number of Comments',
        rowDataKey: 'comment_number'
      },
      {
        key: 'start-date',
        title: 'Start Date',
        rowDataKey: 'creation_date',
      },
      {
        key: 'completion-date',
        title: 'Completion Date',
        rowDataKey: 'completion_date'
      },
      {
        key: 'duration',
        title: 'Duration',
        rowDataKey: 'duration'
      },
    ],
    sortDefault: 'creation_date',
    sortDirection: 'desc',
    paginationKey: PAGINATION_LOCAL_STORAGE_KEY.REPORTS_REVIEWS,
    filterKey: FILTERS_LOCAL_STORAGE_KEY.REPORTS_REVIEWS,
    savedViewsKey: 'reports_reviews',
    searchFilterKey: 'Search Title'
  },
  {
    key: ReportsTitleURLKey.PeriodicReviews,
    title: ReportTitle.PeriodicReviews,
    columns: ['system', 'vendor-name', 'business-owner', 'system-owner', 'periodic-review-date', 'alert'],
    columnsMapping: [
      {
        key: 'system',
        title: 'System',
        rowDataKey: 'system_name',
      },
      {
        key: 'vendor-name',
        title: 'Vendor Name',
        rowDataKey: 'vendor_name'
      },
      {
        key: 'business-owner',
        title: 'Business Owner',
        rowDataKey: 'business_owner'
      },
      {
        key: 'system-owner',
        title: 'System Owner',
        rowDataKey: 'system_owner'
      },
      {
        key: 'periodic-review-date',
        title: 'Periodic Review Date',
        rowDataKey: 'periodic_review_date'
      },
      {
        key: 'alert',
        title: 'Alert (notification sent ahead of Periodic Review Date)',
        rowDataKey: 'alert_days_ahead'
      }
    ],
    sortDefault: 'periodic_review_date',
    sortDirection: 'asc',
    paginationKey: PAGINATION_LOCAL_STORAGE_KEY.REPORTS_PERIODIC_REVIEWS,
    filterKey: FILTERS_LOCAL_STORAGE_KEY.REPORTS_PERIODIC_REVIEWS,
    savedViewsKey: 'reports_periodic_review',
    searchFilterKey: 'Search System'
  }
]

export const REPORTS_LISTING_ADMIN = [
  {
    key: ReportsTitleURLKeyAdmin.Licenses,
    title: ReportTitleAdmin.Licenses,
    columns: ['company', 'total-licenses', 'used-licenses', 'unused-licenses'],
    columnsMapping: [
      {
        key: 'company',
        title: 'Company',
        rowDataKey: 'company_name',
      },
      {
        key: 'total-licenses',
        title: 'Total Licenses',
        rowDataKey: 'total_licenses_count'
      },
      {
        key: 'used-licenses',
        title: 'Used Licenses',
        rowDataKey: 'used_licenses_count'
      },
      {
        key: 'unused-licenses',
        title: 'Unused Licenses',
        rowDataKey: 'unused_licenses_count'
      },
    ],
    sortDefault: 'company_name',
    paginationKey: PAGINATION_LOCAL_STORAGE_KEY.ADMIN_REPORTS_LICENSES,
    filterKey: FILTERS_LOCAL_STORAGE_KEY.ADMIN_REPORTS_LICENSES,
    savedViewsKey: 'reports_licenses',
    searchFilterKey: 'Search Company',
    hasFilters: false
  },
  {
    key: ReportsTitleURLKeyAdmin.Usages,
    title: ReportTitleAdmin.Usages,
    columns: ['company', 'user', 'ecm', 'ilm', 'general-documents', 'requirements-documents', 'trace-matrix-documents', 'test-script-documents', 'executed-test-script-documents'],
    columnsMapping: [
      {
        key: 'company',
        title: 'Company',
        rowDataKey: 'company_name'
      },
      {
        key: 'user',
        title: 'User',
        rowDataKey: 'user_name'
      },
      {
        key: 'ecm',
        title: 'Ecm',
        rowDataKey: 'ecm_workflows_count'
      },
      {
        key: 'ilm',
        title: 'Ilm',
        rowDataKey: 'ilm_workflows_count'
      },
      {
        key: 'general-documents',
        title: 'General Documents',
        rowDataKey: 'general_documents_count'
      },
      {
        key: 'requirements-documents',
        title: 'Requirements Documents',
        rowDataKey: 'requirement_documents_count'
      },
      {
        key: 'trace-matrix-documents',
        title: 'Trace Matrix Documents',
        rowDataKey: 'trace_matrix_documents_count'
      },
      {
        key: 'test-script-documents',
        title: 'Test Script Documents',
        rowDataKey: 'test_script_documents_count'
      },
      {
        key: 'executed-test-script-documents',
        title: 'Executed Test Script Documents',
        rowDataKey: 'executed_test_script_documents_count'
      },
    ],
    sortDefault: 'company_name',
    paginationKey: PAGINATION_LOCAL_STORAGE_KEY.ADMIN_REPORTS_USAGES,
    filterKey: FILTERS_LOCAL_STORAGE_KEY.ADMIN_REPORTS_USAGES,
    savedViewsKey: 'reports_usage',
    searchFilterKey: 'Search company or user',
    hasFilters: true
  },
  {
    key: ReportsTitleURLKeyAdmin.Users,
    title: ReportTitleAdmin.Users,
    columns: ['company', 'user', 'number-of-logins', 'last-login', 'user-role'],
    columnsMapping: [
      {
        key: 'company',
        title: 'Company',
        rowDataKey: 'company_name'
      },
      {
        key: 'user',
        title: 'User',
        rowDataKey: 'user_name'
      },
      {
        key: 'number-of-logins',
        title: 'Number Of Logins',
        rowDataKey: 'user_logins_count'
      },
      {
        key: 'last-login',
        title: 'Last Login',
        rowDataKey: 'user_last_login_at',
        isGMTDate: true
      },
      {
        key: 'user-role',
        title: 'User Role',
        rowDataKey: 'user_role'
      },
    ],
    sortDefault: 'company_name',
    paginationKey: PAGINATION_LOCAL_STORAGE_KEY.ADMIN_REPORTS_USERS,
    filterKey: FILTERS_LOCAL_STORAGE_KEY.ADMIN_REPORTS_USERS,
    savedViewsKey: 'reports_users',
    searchFilterKey: 'Search company or user',
    hasFilters: true
  },
]

export const REPORTS_PERMISSIONS_TOOLTIP = {
  GENERIC_PERMISSION_IS_ON: 'View all reports permission is ON so you are able to see all records, not only to which you have access (ACL) on.',
  GENERIC_PERMISSION_IS_OFF: 'View all reports permission is OFF so you are able to only see records to which you have access (ACL) on.',
  TASKS_PERMISSION_IS_ON: 'View all reports permission is ON so you are able to see all tasks.',
  TASKS_PERMISSION_IS_OFF: 'View all reports permission is OFF so you are able to only see your tasks.',
}

export const ALERTS = [
  {
    value: '30',
    label: '30 days ahead'
  },
  {
    value: '60',
    label: '60 days ahead'
  },
  {
    value: '90',
    label: '90 days ahead'
  },
];

export const BULK_SHARE_TEMPLATES_SAVED_VIEW_KEY = 'bulk_share_templates';
export const BULK_TRANSFER_TEMPLATES_SAVED_VIEW_KEY = 'bulk_transfer_templates';
export const BULK_SHARE_WORKSPACE_SAVED_VIEW_KEY = 'bulk_share_documents';
export const BULK_TRANSFER_WORKSPACE_SAVED_VIEW_KEY = 'bulk_transfer_documents';

export const REPORTS_FILTERS_WITH_FORMATTED_LABELS = ['workflow_status', 'deliverable_type', 'document_type', 'task_type', 'delayed', 'document_category']
export const REPORTS_USER_COMPANY_FILTERS = ['filters.owner', 'filters.reviewers', 'filters.approvers', 'filters.testers', 'filters.user', 'filters.assigners', 'filters.groups', 'filters.company']
export const REPORTS_WORKFLOW_FILTER = ['filters.workflow_uuid', 'filters.workflow_root_uuid', 'filters.document_uuid'];
export const REPORTS_IGNORED_COLUMN_KEYS = ['workflow_root_uuid', 'workflow_uuid', 'document_category'];
export const TOOLTIP_MESSAGE_SEND_FOR_EXECUTION = `Execution target date is for the whole execution lifecycle. This includes the execution, review and approval session. \n The execution task for the user will close only when the execution has been approved or canceled.`;
export const TOOLTIP_MESSAGE_SEND_FOR_REVIEW_AND_SIGNATURE = 'This will only save the users in this list but it will not send a task to them.';
export const TOOLTIP_MESSAGE_SEND_FOR_SIGNATURE_PLACEHOLDER_USER = 'The current user is automatically added as a suggestion. If you want to add this user to the assignment list you need to click on “Save list”.';

export const REPORTS_QUALITY_DASHBOARD_CARDS = [ReportsQualityDashboardCardCategories.ApprovedWorkflows, ReportsQualityDashboardCardCategories.SystemsPeriodicReview, ReportsQualityDashboardCardCategories.Systems, ReportsQualityDashboardCardCategories.ExecutionsWithDeviations, ReportsQualityDashboardCardCategories.ActiveWorkflows];
export const REPORTS_VALIDATION_PROJECT_DASHBOARD_CARDS = [ReportsValidationProjectDashboardCardCategories.LinkedRequirements, ReportsValidationProjectDashboardCardCategories.ActiveExecutions, ReportsValidationProjectDashboardCardCategories.AverageTimePerExecution, ReportsValidationProjectDashboardCardCategories.OverdueTasks, ReportsValidationProjectDashboardCardCategories.Documents];
export const REPORTS_PROJECT_MANAGEMENT_DASHBOARD_CARDS = [ReportsProjectManagementDashboardCardCategories.Assignments, ReportsProjectManagementDashboardCardCategories.CurrentTasks, ReportsProjectManagementDashboardCardCategories.AverageTimePerTask, ReportsProjectManagementDashboardCardCategories.MostAssignments, ReportsProjectManagementDashboardCardCategories.MostFinishedTasksWithDelays, ReportsProjectManagementDashboardCardCategories.MostFinishedTasks];
export const REPORTS_PROJECT_COMPARISON_DASHBOARD_CARDS = [ReportsProjectComparisonDashboardCardCategories.TotalLinkedDocuments, ReportsProjectComparisonDashboardCardCategories.TestScriptExecutions, ReportsProjectComparisonDashboardCardCategories.AverageTimePerExecution, ReportsProjectComparisonDashboardCardCategories.AverageTimePerTask, ReportsProjectComparisonDashboardCardCategories.Workflows];
