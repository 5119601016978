import { IWorkflowRuntime, RuntimeWorkflowStatus, SdoxDocumentCategory } from '@core/interfaces';
import { IReviewSessionComment, IReviewSessionResponseList, IWorkflowReviewLockResponse } from '@core/interfaces/review.interface';
import { createSelector, Selector } from '@ngxs/store';
import * as _ from 'lodash';
import { DocumentState, DocumentStateModel } from './document.state';
import { CanvasFunctionalElementType } from '../../canvas/canvas.enum';

export class DocumentSelectors {

  @Selector([DocumentState])
  static getTablePageNumbers(state: DocumentStateModel) {
    return state?.tablePageNumbers;
  }

  static getTablePageNumber(stepUuid: string) {
    return createSelector([DocumentSelectors.getTablePageNumbers], tablePageNumbers => {
      if (tablePageNumbers && tablePageNumbers[stepUuid] !== null) {
        return tablePageNumbers[stepUuid];
      }
      return null;
    });
  }

  @Selector([DocumentState])
  static getLoadingDocument(state: DocumentStateModel) {
    return state.loadingDocument;
  }

  @Selector([DocumentState])
  static getLoadingHighlights(state: DocumentStateModel) {
    return state.loadingHighlights;
  }

  @Selector([DocumentState])
  static getLoadingLinks(state: DocumentStateModel) {
    return state ? state.loadingLinks : false;
  }

  @Selector()
  static getRowsPerPage() {
    return 10;
  }

  @Selector([DocumentState])
  static getReviewSessions(state: DocumentStateModel) {
    return state.reviewSessions;
  }

  @Selector([DocumentState])
  static getSelectedComment(state: DocumentStateModel) {
    return state.selectedComment;
  }

  @Selector([DocumentState])
  private static getAllComments(state: DocumentStateModel) {
    return state.reviewSessionComments;
  }

  @Selector([DocumentSelectors.getAllComments, DocumentSelectors.getDocument])
  static getReviewSessionsComments(allComments: IReviewSessionComment[], document: IWorkflowRuntime): IReviewSessionComment[] {
    if (document && allComments && allComments.length > 0) {
      const sortedCommentsByFEOrder: IReviewSessionComment[] = document.steps.reduce((previousStep, currentStep) => {
        const sortedComments = [];
        const titleComments = allComments.filter(comment => comment.for_title && comment.step_uuid === currentStep.uuid);
        const sortedTitleComments = _.sortBy(titleComments, this.sortByOrderIndex);
        sortedComments.push(...sortedTitleComments);

        if (currentStep.step_data?.rows) {
          const columnsKeys = currentStep?.step_data?.columns.map(col => col.key);
          if(columnsKeys.indexOf('attachment') === -1){
            columnsKeys.push('attachment');
          }
          if(columnsKeys.indexOf('impactAssessment') === -1){
            columnsKeys.push('impactAssessment');
          }

          if (columnsKeys.length && 
            (currentStep?.fe_type === CanvasFunctionalElementType.TraceMatrixTable || 
             currentStep?.fe_type === CanvasFunctionalElementType.Table || 
             currentStep?.fe_type === CanvasFunctionalElementType.Test_Script) &&
             currentStep.step_data?.columns?.findIndex(column => column.key === 'links') === -1) {
            columnsKeys.push('links');
          }

          columnsKeys.forEach(columnKey => {
            const tableHeaderComments = allComments
              .filter(comment => comment.step_uuid === currentStep.uuid && comment.column_key === columnKey && comment.row_uuid === null);
            const sortedTableHeaderComments = _.sortBy(tableHeaderComments, this.sortByOrderIndex);
            sortedComments.push(...sortedTableHeaderComments);
          });
          const sortedTableCellComments = currentStep?.step_data?.rows.reduce((previousRow, currentRow) => {
            const tableCellComments = [];
            const rowComments = allComments.filter(comment => comment.row_uuid === currentRow.uuid);
            if (rowComments.length) {
              columnsKeys.forEach(columnKey => {
                const cellRowComments = rowComments.filter(comment => comment.column_key === columnKey);
                tableCellComments.push(...(_.sortBy(cellRowComments, this.sortByOrderIndex)));
              });
            }

            return [
              ...previousRow,
              ...tableCellComments
            ];
          }, []);
          sortedComments.push(...sortedTableCellComments);
        } else {
          const generalFEComments = allComments.filter(comment => comment.step_uuid === currentStep.uuid
            && !comment.column_key
            && !comment.row_uuid
            && comment.for_title === false);
          const sortedGeneralFEComments = _.sortBy(generalFEComments, this.sortByOrderIndex);
          sortedComments.push(...sortedGeneralFEComments);
        }
        // General FE comments
        return [
          ...previousStep,
          ...sortedComments
        ];
      }, []);
      return sortedCommentsByFEOrder;
    }
    return [];

  }

  static getPendingCommentOnStep(stepUuid: string) {
    return createSelector([DocumentSelectors.getReviewSessionsComments],
      (allComments: IReviewSessionComment[]) => {
        if (allComments.length > 0) {
          return allComments.find(comment => comment.step_uuid === stepUuid && !comment.uuid);
        }
        return null;
      });
  }

  /**
   * Returns comments for a specific step
   * @param cellComment 
   * @returns 
   */
  static getReviewSessionsCommentsForReviewTarget(cellComment: IReviewSessionComment) {
    return createSelector([
      DocumentSelectors.getReviewSessionsComments,
      DocumentSelectors.getDocument], (allComments: IReviewSessionComment[], document: IWorkflowRuntime) => {
        if (allComments.length > 0) {
          if (cellComment.for_title) {
            // title comments
            return allComments.filter((comment: IReviewSessionComment) => comment.for_title && comment.step_uuid === cellComment.step_uuid);
          }
          if (this.isTableHeaderComment(cellComment)(document, allComments)) {
            // table header comments
            return allComments.filter((comment: IReviewSessionComment) =>
              comment.step_uuid === cellComment.step_uuid && comment.column_key === cellComment.column_key && comment.row_uuid === null);
          }

          return allComments.filter((comment: IReviewSessionComment) => {
            const rows = document.steps.find(step => step.uuid === cellComment.step_uuid).step_data?.rows;
            const commentRow = rows?.findIndex(row => row.uuid === cellComment.row_uuid);
            if ((commentRow && commentRow !== -1) || !!cellComment.row_uuid) {
              // table cell comments
              return comment.step_uuid === cellComment.step_uuid;
            } else {
              // general FE comments
              return comment.step_uuid === cellComment.step_uuid
                && !comment.column_key
                && !comment.row_uuid
                && comment.for_title === false;
            }
          });
        }
        return [];
      });
  }

  static getCommentPageNumber(comment: IReviewSessionComment) {
    return createSelector([DocumentSelectors.getDocument, DocumentSelectors.getRowsPerPage],
      (document: IWorkflowRuntime, rowsPerPageInTable) => {
        if (comment) {
          const rows = document.steps.find(step => step.uuid === comment.step_uuid).step_data?.rows;
          const commentRow = rows?.findIndex(row => row.uuid === comment.row_uuid);
          if (commentRow > -1) {
            return Math.floor(commentRow / rowsPerPageInTable);
          }
        }
        return null;
      });
  }

  static isTableHeaderComment(comment: IReviewSessionComment) {
    return createSelector([DocumentSelectors.getDocument, DocumentSelectors.getReviewSessionsComments],
      (document: IWorkflowRuntime, allComments: IReviewSessionComment[]) => {
        if (allComments.length > 0 && document) {
          const columns = document.steps.find(step => step.uuid === comment.step_uuid).step_data?.columns;
          if (!!columns) {
            return allComments.find(c => c.step_uuid === comment.step_uuid
              && columns.findIndex(column => column.key === comment.column_key) > -1 && comment.row_uuid === null) !== undefined;
          }
        }
        return false;
      });
  }

  @Selector([DocumentSelectors.getReviewSessionsComments])
  static hasPendingComment(allComments: IReviewSessionComment[]) {
    if (allComments.length > 0) {
      return allComments.find(comment => !comment.uuid) !== undefined;
    }
    return false;
  }

  @Selector([DocumentState])
  static getLoadingCommentsStatus(state: DocumentStateModel) {
    return state.loadingComments;
  }

  @Selector([DocumentState])
  static getLoadedStatus(state: DocumentStateModel) {
    return state.statusLoaded;
  }

  @Selector([DocumentState])
  static getReviewSessionDropdownItem(state: DocumentStateModel) {
    return {
      label: state.reviewVersionsBtnLabel,
      comments: state.commentCount
    };
  }

  @Selector([DocumentState])
  static getActionsReviewVersions(state: DocumentStateModel) {
    return state.actionsReviewVersions;
  }

  @Selector([DocumentSelectors.getAllComments])
  static getEmptyComments(reviewSessionComments: IReviewSessionComment[]) {
    return reviewSessionComments.filter(comment => comment.text === '');
  }

  @Selector([DocumentState])
  static getDocument(state: DocumentStateModel) {
    return state?.document;
  }

  @Selector([DocumentSelectors.getDocument, DocumentSelectors.getReviewRequestCompleterData])
  static getReviewRequest(document: IWorkflowRuntime, reviewRequestCompleterData: any) {
    if (document?.status === RuntimeWorkflowStatus.Reviewed || document?.status === RuntimeWorkflowStatus.CanceledReviewed) {
      const reviewRequestHistory: any[] = reviewRequestCompleterData?.review_request_history;
      return reviewRequestHistory.find(history => history.review_session_uuid === document.uuid);
    }
    return reviewRequestCompleterData?.review_request;
  }

  @Selector([DocumentSelectors.getDocument])
  static getReviewRequestCompleterData(document: IWorkflowRuntime) {
    return document?.steps && document?.steps[document.steps?.length - 1].step_data;
  }

  @Selector([DocumentSelectors.getDocument, DocumentSelectors.getReviewSessions])
  static getCurrentReviewSessionUuid(document: IWorkflowRuntime, reviewSessions: IReviewSessionResponseList) {
    return reviewSessions.sessions.find(session => session.uuid === document.uuid).uuid;
  }

  @Selector([DocumentState])
  static getLockStatus(state: DocumentStateModel) {
    return state ? state.lockStatus : null;
  }

  @Selector([DocumentSelectors.getLockStatus])
  static getLockedByUserUuid(lockStatus: IWorkflowReviewLockResponse) {
    return lockStatus?.user.uuid;
  }

  @Selector([DocumentState])
  static getActiveSession(state: DocumentStateModel) {
    const sessions = state.reviewSessions?.sessions;
    const document = state.document;
    return sessions ? sessions.find(session => session.uuid === document.uuid) : null;
  }

  private static sortByOrderIndex = (comment: IReviewSessionComment) => comment.order_index;


  @Selector([DocumentSelectors.getDocument])
  static isTestScriptExecution(document: IWorkflowRuntime) {
    if (!!document) {
      return document.sdox_document_category === SdoxDocumentCategory.Test_Script_Execution;
    }
    return false;
  }

  @Selector([DocumentSelectors.getDocument])
  static isDocumentInReview(document: IWorkflowRuntime) {
    if (!!document) {
      return document.status === RuntimeWorkflowStatus.InReview;
    }
    return false;
  }

  @Selector([DocumentState])
  static isDocumentLockedFromThisTab(state: DocumentStateModel) {
    return state?.lockedFromThisTab;
  }

  @Selector([DocumentState])
  static getGeneralFeEnableStatus(state: DocumentStateModel) {
    return state ? state.generalFeEnableStatus : null;
  }

  @Selector([DocumentSelectors.getExecutionRequestCompleterData])
  static getExecutionRequest(executionRequestCompleterData: any) {
    return executionRequestCompleterData?.execution_request;
  }

  @Selector([DocumentSelectors.getDocument])
  static getExecutionRequestCompleterData(document: IWorkflowRuntime) {
    return document?.steps && document?.steps[document.steps?.length - 1].step_data;
  }
}
