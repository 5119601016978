import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { UserListingModalComponent } from './user-listing-modal.component';
import { ButtonsModule } from '../buttons/buttons.module';
import { TableLoadingOverlayModule } from '../table-loading-overlay/table-loading-overlay.module';
import { PageSelectorModule } from '../page-selector/page-selector.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ButtonsModule,
    TableLoadingOverlayModule,
    PageSelectorModule
  ],
  declarations: [
    UserListingModalComponent
  ],
  exports: [
    UserListingModalComponent
  ],
  providers: []
})
export class UserListingModalModule { }
